.widget {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #1a2233;
  filter: drop-shadow(10px 10px 20px rgb(0 0 0 / 25%));

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: #3cff64;
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    overflow: hidden;
    color: #1a2233;
    font-weight: bold;
    font-size: 0.875rem;
    white-space: nowrap;
    text-overflow: ellipsis;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__body {
    flex: 1;
    padding: 0.5rem;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem;
  }

  &__stat {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 0.25rem;
    }
  }
}
